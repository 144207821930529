import { Button } from "@atlas/ui/Button";
import { cn } from "@atlas/ui/helpers";

import { useCurrentUser } from "../../context/user";

const Page404 = () => {
  const user = useCurrentUser();
  return (
    <div className="mx-auto my-8 max-w-lg p-0 text-center">
      <div className="bg-gray-light flex h-48 items-center justify-center">
        <img src="/img/Disappear.svg" alt="Disappearing Illustration" className="h-28" />
      </div>
      <div className="flex flex-col gap-4 p-8">
        <h1>Page Not Found</h1>
        <p>This page does not exist.</p>
        <div className="mt-8 flex flex-wrap justify-center gap-4">
          {user ? (
            <Button href="/user/data-brokers" className="min-w-36">
              Return to Dashboard
            </Button>
          ) : (
            <>
              <Button className={cn("secondary", "min-w-36")} href="/signup">
                Sign Up
              </Button>
              <Button href="/" className="min-w-36">
                Log In
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Page404;
